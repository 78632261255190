import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { handleMobileSearchForm, handleMenu } from '../../actions/index';

import Isvg from 'react-inlinesvg';


import {
    Container,
    Row,
    Col,
    Navbar,
    NavbarBrand,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Nav, NavItem,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption

} from 'reactstrap';
import { changeLanguage } from '../../actions';
import Categories from '../../components/categories';

import facebook_icon from '../../assets/svg/facebook-icon.svg'
import instagram_icon from '../../assets/svg/instagram-icon.svg'
import credit_card_icon from '../../assets/svg/credit-card-icon.svg'
import heart_icon from '../../assets/svg/heart-icon.svg'
import account_icon from '../../assets/svg/account-icon.svg'
import cart_icon from '../../assets/svg/cart-icon.svg'
import phone_icon from '../../assets/svg/phone-icon.svg'
import hamburger_icon from '../../assets/svg/hamburger-icon.svg'
import home_icon from '../../assets/svg/home-icon.svg'
import phone1_icon from '../../assets/svg/phone.svg'
import category_icon from '../../assets/svg/category-icon.svg'

import logo from '../../assets/images/logo.png';
import image from '../../assets/images/no-image.jpg';


import drawerAccount from '../../assets/svg/drawer/account.svg';
import drawerCart from '../../assets/svg/drawer/cart.svg';
import drawerHome from '../../assets/svg/drawer/home.svg';
import drawerLogout from '../../assets/svg/drawer/logout.svg';
import drawerPhone from '../../assets/svg/drawer/phone.svg';
import drawerWishlist from '../../assets/svg/drawer/wishlist.svg';


class HomeHeader extends Component {
    constructor(props) {
        super(props);
        this.registerSocketIOEvents = this.registerSocketIOEvents.bind(this);
        this.suggestions = this.suggestions.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);

        this.state = {
            suggestions: [],
            search: '',
            imageErrors: {},
            width: 0, height: 0
        };

        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
      

    }





    componentDidMount() {
        if (this.props.socketIOClient) {
            this.registerSocketIOEvents();
        }
        document.addEventListener('mousedown', this.handleClickOutside);
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
      
    }

    componentDidUpdate(prevProps) {
        if (this.props.socketIOClient) {
            this.registerSocketIOEvents();
        }


    }

    registerSocketIOEvents() {
        if (this.state._registeredEvents)
            return;

        this.setState({
            _registeredEvents: true
        });

        this.props.socketIOClient.on('suggestions', (data) => {
            console.log(data);

            this.setState({
                imageErrors: {},
                suggestions: data.products
            })
        });



    }
    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
      }
      
    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
        window.removeEventListener('resize', this.updateWindowDimensions);

        if (!this.props.socketIOClient) return;
        this.props.socketIOClient.removeAllListeners("suggestions");
        //this.props.socketIOClient.removeAllListeners("fetchProductVariations");

    }


    suggestions() {
        if (this.state.search.length) {
            this.props.socketIOClient.emit("suggestions", { search: this.state.search });
        }
    }
    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({
                suggestions: []
            })
        }

        if (this.leftMenuRef && !this.leftMenuRef.contains(event.target)) {
            this.setState({
                leftMenu: null
            })
        }

        if (this.rightMenuRef && !this.rightMenuRef.contains(event.target)) {
            this.setState({
                rightMenu: null
            })
        }
    }


    render() {
        return (
            <header>
                <div className="header-top">
                    <Container>
                        <Row>
                            <Col lg="7" className="info">
                                <span>Pratite nas</span>
                                <a href='https://www.facebook.com/wunderland.banjaluka/' target="_blank"><Isvg src={facebook_icon} /></a>
                                <a href='https://www.instagram.com/wunderland.banjaluka/' target="_blank"><Isvg src={instagram_icon} /></a>
                                <span>AKO IMATE PITANJA - POZOVITE NAS +387 65 006 800</span>
                            </Col>
                            <Col lg="5" className="actions">
                                <Link to='/cart'>
                                    <Isvg src={credit_card_icon} />
                                    ZAVRŠI KUPOVINU
                                </Link>
                                <Link to='/account/wishlist'>
                                    <Isvg src={heart_icon} />
                                    LISTA OMILJENIH
                                </Link>
                            </Col>

                        </Row>
                    </Container>
                </div>

                <Container className="header">
                    <Row>
                        <Col lg={{ size: 2, order: 0 }} xs={{ size: 1, order: 0 }} className='hamburger d-lg-none d-xl-none' onClick={() => { this.setState({ leftMenu: !this.state.leftMenu }) }}>
                            <Isvg src={hamburger_icon} />
                        </Col>

                        <Col lg={{ size: 2, order: 0 }} xs={{ size: 2, order: 1 }} className="logo">
                            <Link to='/'><img src={logo} /></Link>
                        </Col>
                        <Col lg={{ size: 6, order: 0 }} xs={{ size: 6, order: 2 }} className="search-form">
                            <input onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    this.props[0].history.push(`/category/&search=${this.state.search}`)
                                }
                            }} onFocus={this.suggestions} value={this.state.search} onChange={(e) => { this.setState({ search: e.target.value }, () => this.suggestions()) }} type="text" placeholder={this.state.width < 500 ? "Pretraži" : "Unesite pojam za pretragu..."} />
                            <i className="mdi mdi-magnify d-block d-lg-none" />
                            <button onClick={() => {
                                this.props[0].history.push(`/category/&search=${this.state.search}`)
                            }}>PRETRAGA</button>
                            {this.state.suggestions.length ?
                                <div className="search-results" ref={(node) => { this.wrapperRef = node; }}>
                                    <h6>Artikli</h6>
                                    <ul>
                                        {
                                            this.state.suggestions.map((item, idx) => {
                                                return (
                                                    <li key={idx}><Link to={`/product/${item.Alias}/${item._id}`}><img onError={() => {
                                                        let imageErrors = this.state.imageErrors;
                                                        imageErrors[item.Image] = true;
                                                        this.setState({ imageErrors })
                                                    }} src={!this.state.imageErrors[item.Images && item.Images.length && item.Images[0]] ? (item.Images && item.Images.length && item.Images[0]) ? item.Images[0] : image : image} />{item.Name}</Link></li>

                                                )
                                            })
                                        }

                                    </ul>
                                </div>
                                : null}
                        </Col>
                        {this.props.uData ?
                            <Col lg={{ size: 3, order: 0 }} md={{ size: 1, order: 0 }} xs={{ size: 1, order: 0 }} className="account d-none d-lg-block">
                                <Isvg src={account_icon} />
                                <Link to='/account'>Nalog</Link>
                            </Col>
                            :
                            <Col lg={{ size: 3, order: 0 }} xs={{ size: 1, order: 0 }} className="account d-none d-lg-block">
                                <Isvg src={account_icon} />
                                <Link to='/login'>Prijava</Link> / <Link to='/register'>Registracija</Link>
                            </Col>
                        }
                        <Col lg={{ size: 1, order: 0 }} xs={{ size: 2, order: 3 }} className="cart">
                            <Link to='/cart' className="cart-button">
                                <Isvg src={cart_icon} />
                                <div className="number">{this.props.cartCount}</div>
                            </Link>
                        </Col>

                        <Col className='d-lg-none d-xl-none mobile-hamburger' xs={{ size: 1, order: 4 }} onClick={() => { this.setState({ rightMenu: !this.state.rightMenu }) }}>
                            <Isvg src={category_icon} />

                        </Col>
                    </Row>

                </Container>

                <div className="spacer d-none  d-lg-block"></div>
                <Container className='d-none d-lg-block'>
                    <Row>
                        <Col lg="8">
                            <ul className="navigation">
                                <li><Link to='/'>POČETNA</Link></li>
                              {/*  <li><Link to='/uber-uns'>{this.props.translate('ÜBER UNS')}</Link></li>*/}
                                <li><Link to='/contact'>KONTAKT</Link></li>
                            </ul>
                        </Col>
                        <Col lg="4" className="contact-phone">
                            <Isvg src={phone_icon} /> +387 65 006 800
                        </Col>
                    </Row>

                </Container>


                <div className={this.state.leftMenu ? 'mobile-left-menu mobile-left-menu-open' : 'mobile-left-menu'}>
                    <div className="content" ref={(node) => this.leftMenuRef = node}>
                        <div className="user-area">
                            <div className="user-image">
                                <Isvg src={account_icon} />
                            </div>

                            {this.props.uData && <p>{this.props.uData.User.Name}</p>}
                            {this.props.uData && <p>{this.props.uData.User.EMail}</p>}
                            {!this.props.uData && <p><Link to='/login'>{this.props.translate('Anmeldung')}</Link> / <Link to='/register'>{this.props.translate('Registrieren')}</Link></p>}

                        </div>

                        <ul>
                            <li> <Link to='/'><Isvg src={drawerHome} />Početna </Link> </li>
                            {this.props.uData && <li> <Link to='/account'><Isvg src={drawerAccount}/> Nalog </Link> </li>}
                            <li> <Link to='/cart'><Isvg src={drawerCart} />Korpa </Link> </li>
                            {this.props.uData && <li> <Link to='/account/wishlist'><Isvg src={drawerWishlist} />Lista omiljenih </Link> </li>}
                            <li> <Link to='/contact'><Isvg src={drawerPhone} />Kontakt </Link> </li>
                            {this.props.uData && <li> <a onClick={() => { this.props.setUserData(null); this.props.socketIOClient.emit('userLogout', {}); }}><Isvg src={drawerLogout} />Odjava </a> </li>}
                            {!this.props.uData && <li> <Link to='/account'><Isvg src={drawerAccount} />Prijava </Link> </li>}

                        </ul>
                    </div>
                </div>

                <div className={this.state.rightMenu ? 'mobile-right-menu mobile-right-menu-open' : 'mobile-right-menu'}>
                    <div className="content" ref={(node) => this.rightMenuRef = node}>
                        <h3>KATEGORIJE</h3>

                        <Categories {...this.props} selectCategory={(cat, level) => {
                            let state = { page: 0 };
                            state['_selectedCategory' + level] = cat._id;

                            this.setState(state);

                        }}

                            _selectedCategory0={this.state._selectedCategory0}
                            _selectedCategory1={this.state._selectedCategory1}
                            _selectedCategory2={this.state._selectedCategory2}
                            _selectedCategory3={this.state._selectedCategory3}
                            _selectedCategory4={this.state._selectedCategory4}


                        />


                    </div>
                </div>

            </header>

        );
    }
}

const mapStateToProps = state => ({
    searchForm: state.searchForm,
    menu: state.menu
});

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        handleChange: (val) => {
            dispatch(handleMobileSearchForm(val))
        },
        handleMenu: (val) => {
            dispatch(handleMenu(val))
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(HomeHeader);
