import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { PageWithLayout } from '../containers/page';
import { connect } from 'react-redux';
import Isvg from 'react-inlinesvg';

import HomeHeader from '../containers/header/homeHeader';
import Footer from '../containers/footer';

import slide from '../assets/images/slide.png';
import banner1 from '../assets/images/banner1.png';
import banner2 from '../assets/images/banner2.png';

import article1 from '../assets/images/article1.png';
import article2 from '../assets/images/article2.png';
import article3 from '../assets/images/article3.png';
import article4 from '../assets/images/article4.png';
import article5 from '../assets/images/article5.png';
import article6 from '../assets/images/article6.png';
import article7 from '../assets/images/article7.png';
import article8 from '../assets/images/article8.png';

import user from '../assets/images/user-review.png';

import blog1 from '../assets/images/blog1.png';
import blog2 from '../assets/images/blog2.png';
import blog3 from '../assets/images/blog3.png';


import Newsletter from '../components/newsletter';
import Article from '../components/article';
import Categories from '../components/categories';

import star_icon from '../assets/svg/star-icon.svg';
import info_icon from '../assets/svg/info-icon.svg';


import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle
} from 'reactstrap';

import ResetPasswordForm from '../components/forms/resetPasswordForm';

class ResetPasswordPage extends Component {
    constructor(props) {
        super(props);
        this.registerSocketIOEvents = this.registerSocketIOEvents.bind(this);
        this.resetPassword = this.resetPassword.bind(this);

        this.state = {

        };

    }


    componentDidMount() {
        if (this.props.socketIOClient) {
            this.registerSocketIOEvents();
        }

    }

    componentDidUpdate(prevProps) {
        if (this.props.socketIOClient) {
            this.registerSocketIOEvents();
        }

    }

    registerSocketIOEvents() {
        if (this.state._registeredEvents)
            return;

        this.setState({
            _registeredEvents: true
        });

        this.props.socketIOClient.on('userResetPassword', (data) => {
            console.log(data);
            if (data.error) {
                if (data.error == 'passwords-not-match') {
                    this.setState({
                        error: this.props.translate('Lozinke se ne podudaraju')
                    })
                }
            } else {
                this.props.setUserData(data.user);
                this.props[0].history.push('/account');

            }
        });



    }

    componentWillUnmount() {
        if (!this.props.socketIOClient) return;
        //this.props.socketIOClient.removeAllListeners("fetchProduct");
        //this.props.socketIOClient.removeAllListeners("fetchProductVariations");

    }


    resetPassword(data) {
        console.log(data);
        data.uid = this.props[0].match.params.uid;
        data.code = this.props[0].match.params.code;

        this.props.socketIOClient.emit("userResetPassword", data);
    }




    render() {
        let product = this.state.product;

        return (
            <div className="account-wrap">

                {
                    this.props.uData ? <Redirect to='/account' ></Redirect> : null
                }


                <Container>
                    <Row>
                        <Col lg="3" className="d-none d-lg-block">
                            <div className="categories">
                                <h3>{this.props.translate('KATEGORIJE')}</h3>
                                <Categories {...this.props} selectCategory={(cat, level) => {
                                    let state = { page: 0 };
                                    state['_selectedCategory' + level] = cat._id;

                                    this.setState(state);

                                }}

                                    _selectedCategory0={this.state._selectedCategory0}
                                    _selectedCategory1={this.state._selectedCategory1}
                                    _selectedCategory2={this.state._selectedCategory2}
                                    _selectedCategory3={this.state._selectedCategory3}
                                    _selectedCategory4={this.state._selectedCategory4}


                                />

                            </div>
                        </Col>
                        <Col lg="9" className="content-wrap">
                            <Container>
                                <Row>
                                    <Col lg="12">
                                        <ul className="breadcrumb">
                                            <li><Link to='/'>{this.props.translate('Nalog')}</Link></li>
                                            <li><Link to='/account/password-reset'>{this.props.translate('Lozinka')}</Link></li>

                                        </ul>
                                    </Col>
                                    <Col lg="5">
                                        <h1>{this.props.translate('Lozinka')}</h1>
                                    </Col>
                                    <Col lg="7">
                                    </Col>
                                    <Col lg="12">
                                        <Container className="box-container">
                                            <Row>

                                                <Col lg="12" >

                                                    <ResetPasswordForm translate={this.props.translate} onSubmit={this.resetPassword}></ResetPasswordForm>

                                                    {
                                                        this.state.error ?
                                                            <p className="error-text">{this.state.error}</p>
                                                            : null
                                                    }
                                                </Col>

                                            </Row>
                                        </Container>



                                    </Col>

                                </Row>
                            </Container>

                        </Col>
                    </Row>
                </Container>


                <Newsletter {...this.props} />


                <Footer {...this.props} />



            </div >
        );
    }
}



const mapStateToProps = state => ({
    menu: state.menu
});



export default connect(mapStateToProps)(PageWithLayout(ResetPasswordPage));
