import React, { Component } from 'react';
import { Link } from 'react-router-dom';


import {
    Container,
    Row,
    Col,
    CarouselItem,
    Carousel,
    CarouselControl,
    CarouselIndicators
} from 'reactstrap';


export class HomeSlider extends Component {
    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.goToIndex = this.goToIndex.bind(this);
        this.onExiting = this.onExiting.bind(this);
        this.onExited = this.onExited.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

        this.state = {
            activeIndex: 0,
            width: 0
        };
    }



    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth });
    }

    onExiting() {
        this.animating = true;
    }

    onExited() {
        this.animating = false;
    }

    next() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === this.props.items.length - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({ activeIndex: nextIndex });
    }

    previous() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === this.props.items.length ? 1 : this.state.activeIndex - 1;
        this.setState({ activeIndex: nextIndex });
    }

    goToIndex(newIndex) {
        if (this.animating) return;
        this.setState({ activeIndex: newIndex });
    }


    render() {
        const { activeIndex } = this.state;



        const slides = this.props.items.map((item, idx) => {
            let image;
            if (this.state.width < 600) {
                image = item.PhoneImage;
            }else if (this.state.width >= 600 && this.state.width < 1025){
                image = item.TabletImage;
            }else{
                image = item.DesktopImage;
            }
            return (
                <CarouselItem
                    onExiting={this.onExiting}
                    onExited={this.onExited}
                    key={idx}
                >
                    <img src={image} alt={item.title} />
                    <div className="content">
                        <h6>{item.subtitle}</h6>
                        <h3>{item.title}</h3>
                        <p>{item.description}</p>
                        <Link to={item.link ? item.link : ''} className="button">{this.props.translate('KUPI ODMAH')}</Link>
                    </div>
                </CarouselItem>
            );
        });


        return (
            <Carousel
                activeIndex={activeIndex}
                next={this.next}
                previous={this.previous}
                className="home-slider carousel-fade"
            >
                <CarouselIndicators items={this.props.items} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
                {slides}
            </Carousel>
        )
    }
}

export default HomeSlider;