import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { PageWithLayout } from '../containers/page';
import { connect } from 'react-redux';

import HomeHeader from '../containers/header/homeHeader';
import Footer from '../containers/footer';

import slide from '../assets/images/slide.png';
import banner1 from '../assets/images/banner1.png';
import banner2 from '../assets/images/banner2.png';

import article1 from '../assets/images/article1.png';
import article2 from '../assets/images/article2.png';
import article3 from '../assets/images/article3.png';
import article4 from '../assets/images/article4.png';
import article5 from '../assets/images/article5.png';
import article6 from '../assets/images/article6.png';
import article7 from '../assets/images/article7.png';
import article8 from '../assets/images/article8.png';

import user from '../assets/images/user-review.png';

import blog1 from '../assets/images/blog1.png';
import blog2 from '../assets/images/blog2.png';
import blog3 from '../assets/images/blog3.png';


import Newsletter from '../components/newsletter';
import Article from '../components/article';
import BlogArticle from '../components/blogArticle';
import HomeSlider from '../components/homeSlider';
import ReviewsSlider from '../components/reviewsSlider';


import {
    Container,
    Row,
    Col,
} from 'reactstrap';


class HomePage extends Component {
    constructor(props) {
        super(props);
        this.registerSocketIOEvents = this.registerSocketIOEvents.bind(this);

        this.state = {
            activeIndex: 0,
            banners: [
            ],
           
            slides: [
                
            ],
            popularProducts: []

        };

    }



componentDidMount() {
    if (this.props.socketIOClient) {
        this.registerSocketIOEvents();
    }

}

componentDidUpdate(prevProps) {
    if (this.props.socketIOClient) {
        this.registerSocketIOEvents();
    }


}

registerSocketIOEvents() {
    if (this.state._registeredEvents)
        return;

    this.setState({
        _registeredEvents: true
    });

    this.props.socketIOClient.on('fetchPopularProducts', (data) => {
        this.setState({
            popularProducts: data
        })
    });

    this.props.socketIOClient.on('fetchSlides', (data) => {
        console.log(data);
        this.setState({
            slides: data
        })
    });

    this.props.socketIOClient.on('fetchBanners', (data) => {
        console.log(data);
        this.setState({
            banners: data
        })
    });

    this.props.socketIOClient.emit("fetchPopularProducts", {});

    this.props.socketIOClient.emit("fetchSlides", {});

    this.props.socketIOClient.emit("fetchBanners", {});

}

componentWillUnmount() {
    if (!this.props.socketIOClient) return;
    //this.props.socketIOClient.removeAllListeners("fetchProduct");
    //this.props.socketIOClient.removeAllListeners("fetchProductVariations");

}



    render() {

        console.log(this.props.categories);
        return (
            <div className="home-wrap">


                <Container className="main">
                    <Row>

                        <Col lg="3" className="d-none d-lg-block">
                            <div className="categories">
                                <h3>KATEGORIJE</h3>
                                <ul>
                                    {
                                        this.props.categories.map((item, idx) => {
                                            console.log(item);
                                            return (
                                                <li key={idx}><Link to={'/category' + item.Breadcrumb}>{item.Name}</Link>
                                                    {
                                                        item.subcategories && item.subcategories.length ?
                                                            <div className="submenu">


                                                                {item.subcategories && item.subcategories.map((subcat, idx1) => {
                                                                    return (
                                                                        <ul key={idx1}>
                                                                            <Link to={'/category' + subcat.Breadcrumb}><h5>{subcat.Name}</h5></Link>
                                                                            {subcat.subcategories && subcat.subcategories.map((subcat1, idx2) => {
                                                                                return (
                                                                                    <li key={idx2}><Link to={'/category' + subcat1.Breadcrumb}>{subcat1.Name}</Link></li>
                                                                                )
                                                                            })}

                                                                        </ul>

                                                                    )
                                                                })
                                                                }
                                                            </div>

                                                            : null

                                                    }

                                                </li>

                                            )
                                        })
                                    }

                                </ul>

                            </div>
                        </Col>
                        <Col lg="9">
                            <HomeSlider items={this.state.slides}  translate={this.props.translate} />
                        </Col>



                        {
                            this.state.banners.map((item, idx) => {
                                return (
                                    <Col lg="6" xs="6" className="banner">
                                        <div key={idx}>
                                            <div className="content">
                                                <h3>{item.title && item.title}</h3>
                                                <h6>{item.subtitle && item.subtitle}</h6>
                                                <Link to={item.link ? item.link : '/'} className="button">{this.props.translate('VIŠE...')}</Link>
                                            </div>

                                            <img src={item.Image} />
                                        </div>
                                    </Col>

                                )
                            })
                        }

                    </Row>
                </Container>


                <section className="section section-articles">
                    <Container>
                        <Row>
                            <Col lg="12">
                                <h2>
                                    POPULARNI <span>ARTIKLI</span>
                                </h2>
                            </Col>
                            {
                                this.state.popularProducts.map((item, idx) => {
                                    /*if (!item.price){
                                        return null;
                                    }*/
                                    return (
                                        <Col lg="3" sm="6" xs="6" key={idx} className="article-container1">
 <Article
                                                _id={item._id}
                                                alias={item.Alias}
                                                image={item.Images && item.Images.length && item.Images[0]}
                                                title={item.Name }
                                                discount={item.discount}
                                                price={item.price ? item.price : 0}
                                                inStock={item.StockLevel > 0 ? true : false}
                                                translate={this.props.translate}>
                                            </Article>                                        </Col>
                                    )
                                })
                            }

                        </Row>
                    </Container>
                </section>


                <section className="section section-articles">
                    <Container>
                        <Row>
                            <Col lg="12">
                                <h2>
                                   NOVI <span>ARTIKLI</span>
                                </h2>
                            </Col>
                            {
                                this.props.newestProducts.map((item, idx) => {
                                   /* if (!item.price){
                                        return null;
                                    }*/
                                    return (
                                        <Col lg="3" sm="6" xs="6" key={idx} className="article-container1">
                                            <Article
                                                _id={item._id}
                                                alias={item.Alias}
                                                title={item.Name }
                                                discount={item.discount}
                                                image={item.Images && item.Images.length && item.Images[0]}

                                                price={item.price ? item.price : 0}
                                                inStock={item.StockLevel > 0 ? true : false}
                                                translate={this.props.translate}>
                                            </Article>
                                        </Col>
                                    )
                                })
                            }

                        </Row>
                    </Container>
                </section>


                {/*
                <ReviewsSlider items={this.state.reviews} />


                <section className="section section-news">
                    <Container>
                        <Row>
                            <Col lg="12">
                                <h2>
                                    {this.props.translate('NEUESTEN')} <span>{this.props.translate('NACHRICHTEN')}</span>
                                </h2>
                            </Col>
                            {
                                this.state.news.map((item, idx) => {
                                    return (
                                        <Col lg="4">
                                            <BlogArticle {...item} translate={this.props.translate}></BlogArticle>
                                        </Col>
                                    )
                                })
                            }

                        </Row>
                    </Container>
                </section>
                */}


                <Newsletter {...this.props} />


                <Footer {...this.props} />



            </div >
        );
    }
}



const mapStateToProps = state => ({
    menu: state.menu
});



export default connect(mapStateToProps)(PageWithLayout(HomePage));
