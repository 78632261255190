import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import Isvg from 'react-inlinesvg';

import {
    Container,
    Row,
    Col,

} from 'reactstrap';



import logo from '../assets/images/logo.png';
import fb_icon from '../assets/svg/facebook-icon.svg';
import instagram_icon from '../assets/svg/instagram-icon.svg';

export class Footer extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }


    render() {
        return (
            <footer className="footer">
                <Container>

                    <Row>
                        <Col md="4">
                            <div className="logo">
                                <img src={logo} />
                            </div>
                            <div className="description">
                                 <p>Igraonica Wunderland predstavlja<br/> do sada neviđeni koncept igre i zabave u Banjaluci.</p>
                            </div>

                            <div className="social">
                                <p>Pratite nas

                                                                            <a href="https://www.facebook.com/wunderland.banjaluka/" target="_blank"><Isvg src={fb_icon} /></a>
                                    <a href="https://www.instagram.com/wunderland.banjaluka/" target="_blank"><Isvg src={instagram_icon} /></a>


                                </p>
                            </div>
                        </Col>

                        <Col md="4">
                            <div className="contact">
                                <i className="mdi mdi-phone" /> POZOVITE NAS
                                    <p>+387 65 006 800<br/></p>
                                <div className="d-block d-sm-none">
                                    <i className="mdi mdi-map-marker" />LOKACIJA
                                    <p>Jovana Dučića 25a, 78000<br />
                                        Banja Luka</p>

                                </div>
                            </div>

                            <div className="nav">
                                <h6>NAVIGACIJA</h6>
                                <ul>

                                    <li><Link to='/'>Početna</Link></li>
                                    <li><Link to='/cart' >Korpa</Link></li>
                                    <li><Link to='/contact'>Kontakt</Link></li>





                                </ul>
                            </div>
                        </Col>

                        <Col md="4">
                            <div className="contact d-none d-sm-block">
                                <i className="mdi mdi-map-marker" /> LOKACIJA
                                <p>Jovana Dučića 25a, 78000<br />
                                    Banja Luka</p>
                            </div>

                            <h6>RADNO VRIJEME</h6>
                            <h6>PON-PET</h6>
                            <p>10.00 - 22.00</p>
                            <h6>SUB-NED</h6>
                            <p>10.00 - 22.00</p>


                        </Col>

                        <div className="spacer"></div>

                        <div className="copyright">
                            <p>Copyright © Wunderland - 2019. All Rights Reserved.</p>
                            <p>Created by <span><a href="https://www.novamedia.agency">NOVA media</a></span></p>
                        </div>

                    </Row>
                </Container>

            </footer>
        )
    }
}

export default Footer;