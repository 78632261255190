import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import image from '../assets/images/no-image.jpg';

export class Article extends Component {
    constructor(props) {
        super(props);

        this.state = {
            
        };
    }


    render() {
        return (
            <Link to={`/product/${this.props.alias}/${this.props._id}`} className="article">
                <article >
                    <img  onError={() => {this.setState({imageError: true})}} src={!this.state.imageError ? this.props.image ? this.props.image : image :  image} />
                    {this.props.discount ? 
                        <div className="discount">{this.props.discount}%</div>
                    :
                    null
                    }
                    <h6>{this.props.title}</h6>
                    <p>
                        {this.props.price && parseFloat(this.props.price) ? <span className="price">{parseFloat(this.props.price).toFixed(2)} KM</span> : null}
                        {this.props.inStock ? <span className="in-stock">
                            {this.props.translate('Dostupno')}
                        </span> : null}
                    </p>
                </article>
            </Link>

        )
    }
}

export default Article;