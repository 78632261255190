import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import HomeHeader from './header/homeHeader';



export const DefaultLayout = (Wrapped) => (props) => {
    return (

        <div onTouchEnd={props.touchEnd} onTouchMove={props.touchMove}>
            { /* props._menuOpen ? 
                <Menu {...props} />

            : 
            null
            
            
            <ul className="mobile-navigation hide-desktop">
                <li><Link to='/'>Početna</Link></li>
                <li><Link to='/uber-uns'>Über uns</Link></li>
                <li> <Link to='/fahrzeuge'>Fahrzeuge</Link></li>
                <li><Link to='/service'>Service</Link></li>
                <li><Link to='/galerie'>Galerie</Link></li>
                <li><Link to='/kontakt' >Kontakt</Link></li>
            </ul>
*/
            }
            <HomeHeader {...props} />
            <Wrapped {...props} />
            

            {!props.cookies ? <div className="cookies">
                <p>Mi koristimo kolačiće za poboljšanje vašeg iskustva na našoj web stranici. Korištenjem ove web stranice pristajete na upotrebu kolačića.</p>
                <button onClick={() => { props.allowCookies() }}>PRIHVATAM</button>
                <Link to='/privacy-policy' >VIŠE</Link>
            </div> :
                null
            }
            {
                /* props._modalOpen ?
                 <Modal {...props} /> : null
                 */
            }
        </div>
    );
};

export default DefaultLayout;