import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { PageWithLayout } from '../containers/page';
import { connect } from 'react-redux';
import Isvg from 'react-inlinesvg';

import HomeHeader from '../containers/header/homeHeader';
import Footer from '../containers/footer';

import slide from '../assets/images/slide.png';
import banner1 from '../assets/images/banner1.png';
import banner2 from '../assets/images/banner2.png';

import article1 from '../assets/images/article1.png';
import article2 from '../assets/images/article2.png';
import article3 from '../assets/images/article3.png';
import article4 from '../assets/images/article4.png';
import article5 from '../assets/images/article5.png';
import article6 from '../assets/images/article6.png';
import article7 from '../assets/images/article7.png';
import article8 from '../assets/images/article8.png';

import user from '../assets/images/user-review.png';

import blog1 from '../assets/images/blog1.png';
import blog2 from '../assets/images/blog2.png';
import blog3 from '../assets/images/blog3.png';


import Newsletter from '../components/newsletter';
import Article from '../components/article';
import Categories from '../components/categories';

import star_icon from '../assets/svg/star-icon.svg';
import info_icon from '../assets/svg/info-icon.svg';


import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle
} from 'reactstrap';

import LoginForm from '../components/forms/loginForm';

class AboutPage extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };

    }






    render() {
        let product = this.state.product;

        return (
            <div className="account-wrap">



                <Container>
                    <Row>
                        <Col lg="3" className="d-none d-lg-block">
                            <div className="categories">
                                <h3>{this.props.translate('KATEGORIJE')}</h3>
                                <Categories {...this.props} selectCategory={(cat, level) => {
                                    let state = { page: 0 };
                                    state['_selectedCategory' + level] = cat._id;

                                    this.setState(state);

                                }}

                                    _selectedCategory0={this.state._selectedCategory0}
                                    _selectedCategory1={this.state._selectedCategory1}
                                    _selectedCategory2={this.state._selectedCategory2}
                                    _selectedCategory3={this.state._selectedCategory3}
                                    _selectedCategory4={this.state._selectedCategory4}


                                />

                            </div>
                        </Col>
                        <Col lg="9" className="content-wrap">
                            <Container>
                                <Row>
                                    <Col lg="12">
                                        <ul className="breadcrumb">
                                            <li><Link to='/'>{this.props.translate('Početna')}</Link></li>

                                            <li><Link to='/terms-and-conditions'>{this.props.translate('Allgemeine Geschäftsbedingungen')}</Link></li>

                                        </ul>
                                    </Col>
                                    <Col lg="12">
                                        <h1>{this.props.translate('ÜBER UNS')}</h1>
                                    </Col>
                                    <Col lg="12">
                                        <Container className="box-container">
                                            <Row>

                                                <Col lg="12" >

<p>Unternehmen seit 1996 befassen wir uns mit Schlüssel und Schlüsselfräsmaschinen.<br/><br/>
Als weiteren Service bieten wir für Schuhmacher  eine große Auswahl an Sohlen , Absätzen  und  Zubehör wie  Kleber ,Sohlenplatten.<br/><br/>
Seit  1.1.2017 haben wir unseren Betrieb in eigenen Gebäuden in 89542 Herbrechtingen.<br/><br/>
Wir bieten Ihnen einen erstklassigen Service rund um das Thema Schlüssel Fahrzeugschlüssel . <br/><br/>
Schlüsselfräsmaschinen mechanisch und vollautomatisch sind  am Lager.Intensive Einweisung ist garantiert auch vor Ort. Wir bieten auch Reparaturservice an.<br/><br/>
Sie brauchen Ersatz-Schlüssel . Wir sind für Autoschlüssel und Haustürschlüssel  Ihr Partner.<br/><br/>
Fernbedienungen, komplette Funkschlüssel mit Transponder  bieten wir Lösungen an.<br/><br/>
In  89542 Herbrechtingen  bieten wir Ihnen nach Terminabsprache , Autoschlüssel , Gehäuse Reparaturen, Zylinderschlüssel  an.<br/><br/>
Selbst das Erstellen von Schließanlagen  ist unser Service  bei unseren Kunden geschätzt.<br/><br/>
Gemeinsam mit unseren Kunden die Marktchancen zu nutzen und zusätzlich Umsatz zu generieren, ist eine Herausforderung , der wir uns gerne immer wieder stellen. <br/><br/>
Ihre Fragen und Wünsche werden  telefonisch oder per mail a-key-gmbh.com beantwortet</p>
                                                </Col>

                                            </Row>
                                        </Container>



                                    </Col>

                                </Row>
                            </Container>

                        </Col>
                    </Row>
                </Container>


                <Newsletter {...this.props} />


                <Footer {...this.props} />



            </div >
        );
    }
}



const mapStateToProps = state => ({
    menu: state.menu
});



export default connect(mapStateToProps)(PageWithLayout(AboutPage));
