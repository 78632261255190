import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { PageWithLayout } from '../containers/page';
import { connect } from 'react-redux';
import Isvg from 'react-inlinesvg';

import HomeHeader from '../containers/header/homeHeader';
import Footer from '../containers/footer';

import slide from '../assets/images/slide.png';
import banner1 from '../assets/images/banner1.png';
import banner2 from '../assets/images/banner2.png';

import article1 from '../assets/images/article1.png';
import article2 from '../assets/images/article2.png';
import article3 from '../assets/images/article3.png';
import article4 from '../assets/images/article4.png';
import article5 from '../assets/images/article5.png';
import article6 from '../assets/images/article6.png';
import article7 from '../assets/images/article7.png';
import article8 from '../assets/images/article8.png';

import user from '../assets/images/user-review.png';

import blog1 from '../assets/images/blog1.png';
import blog2 from '../assets/images/blog2.png';
import blog3 from '../assets/images/blog3.png';


import Newsletter from '../components/newsletter';
import Article from '../components/article';
import Categories from '../components/categories';

import star_icon from '../assets/svg/star-icon.svg';
import list_icon from '../assets/svg/list-view.svg';
import image from '../assets/images/no-image.jpg';


import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle
} from 'reactstrap';


class DetailPage extends Component {
    constructor(props) {
        super(props);
        this.registerSocketIOEvents = this.registerSocketIOEvents.bind(this);
        this.addToWishlist = this.addToWishlist.bind(this);
        this.addToCart = this.addToCart.bind(this);
        this.onTouchMove = this.onTouchMove.bind(this);
        this.onTouchEnd = this.onTouchEnd.bind(this);
        this.onTouchStart = this.onTouchStart.bind(this);

        this.state = {
            attributes: [],
            variations: [],
            product: {},

        };

    }


    componentDidMount() {
        if (this.props.socketIOClient) {
            this.registerSocketIOEvents();
        }

    }


    generateBreadcrumb(items, breadcrumb, level) {
        let broken = breadcrumb.split("/");
        let arr = [];

        if (level >= broken.length) {
            return [];
        }

        for (let i = 0; i < items.length; i++) {
            if (broken[level] == items[i].alias) {

                arr.push({
                    _id: items[i]._id,
                    name: items[i].name,
                    link: "/category/" + (items[i].breadcrumb === "" ? items[i].alias : items[i].breadcrumb + "/" + items[i].alias)
                });

                if (items[i].subcategories && items[i].subcategories.length) {
                    arr = arr.concat(this.generateBreadcrumb(items[i].subcategories, breadcrumb, level + 1));
                }

                return arr;
            }
        }

    }

    componentDidUpdate(prevProps) {
        if (this.props.socketIOClient) {
            this.registerSocketIOEvents();
        }

        //console.log(this.props[0]);
        if (prevProps[0].location.pathname !== this.props[0].location.pathname) {

            this.setState({
                product: {},
            }, () => {
                this.props.socketIOClient.emit('fetchProduct', { _id: this.props[0].match.params.id });
            });
        }

        if (!this.props.breadcrumb.length && this.props.categories.length && this.state.product && this.state.product._id && this.state.product.breadcrumb && !this.state._updatingBreadcrumb) {
            let breadcrumb = this.generateBreadcrumb(this.props.categories, this.state.product.breadcrumb, 0);
            this.setState({
                _updatingBreadcrumb: true
            })
            this.props.updateBreadcrumb(!breadcrumb ? [] : breadcrumb);
        }

    }

    registerSocketIOEvents() {
        if (this.state._registeredEvents)
            return;

        this.setState({
            _registeredEvents: true
        });


        this.props.socketIOClient.on('fetchProduct', (data) => {
            console.log(data);
            this.setState({
                product: data,
                previewImage: data.Images && data.Images.length && data.Images[0]
            });
        });


        this.props.socketIOClient.on('addToCart', (data) => {
            console.log(data);
            if (data.successful) {
                this.props.showInfoMessage(this.props.translate('Artikal je uspješno dodat u korpu.'));
                this.props.socketIOClient.emit('cartInfo', {});
            } else {
                this.props.showInfoMessage(this.props.translate('Nema dovoljno na stanju.'), true);

            }
        });

        this.props.socketIOClient.on('addToWishlist', (data) => {
            console.log(data);
            this.props.showInfoMessage(this.props.translate('Artikal je dodat u listu omiljenih.'));

        });




        this.props.socketIOClient.emit('fetchProduct', { _id: this.props[0].match.params.id });


    }

    componentWillUnmount() {
        if (!this.props.socketIOClient) return;
        this.props.socketIOClient.removeAllListeners("fetchProduct");
        this.props.socketIOClient.removeAllListeners("addToWishlist");
        this.props.socketIOClient.removeAllListeners("addToCart");

    }



    addToWishlist() {
        if (!this.props.uData) {
            this.props[0].history.push('/login');
            return;
        }

        this.props.socketIOClient.emit('addToWishlist', { _id: this.props[0].match.params.id });
    }


    addToCart() {
        this.props.socketIOClient.emit('addToCart', { _id: this.props[0].match.params.id, quantity: parseInt(this.state.product.MinOrder) });

    }


    onTouchStart(event) {
        var x = event.clientX;
        var y = event.clientY;
        if (!this.state._startSwipePos) {
            this.setState({
                _startSwipePos: x,
                _startSwipePosY: y,
                _startLeft: this.carousel.scrollLeft
            });
        }
    }

    onTouchEnd() {
        this.setState({
            _startSwipePos: null,
            _startSwipePosY: null,
            _startLeft: null
        });
    }

    onTouchMove(event) {
        var x = event.clientX;
        var y = event.clientY;

        if (this.state._startSwipePos) {
            this.carousel.scrollLeft = this.state._startLeft - (x - this.state._startSwipePos);
        }

        this.setState({
            _swipePos: x
        });


    }


    render() {
        let product = this.state.product;
        return (
            <div className="detail-wrap">


                <Container>
                    <Row>
                        <Col lg="3" className="d-none d-lg-block">
                            <div className="categories">
                                <h3>{this.props.translate('KATEGORIJE')}</h3>
                                <Categories {...this.props} selectCategory={(cat, level) => {
                                    let state = { page: 0 };
                                    state['_selectedCategory' + level] = cat._id;

                                    this.setState(state);

                                }}

                                    _selectedCategory0={this.state._selectedCategory0}
                                    _selectedCategory1={this.state._selectedCategory1}
                                    _selectedCategory2={this.state._selectedCategory2}
                                    _selectedCategory3={this.state._selectedCategory3}
                                    _selectedCategory4={this.state._selectedCategory4}


                                />

                            </div>
                        </Col>
                        <Col lg="9" className="content-wrap">
                            <Container>
                                <Row>
                                    <Col lg="12">
                                        <ul className="breadcrumb">
                                            <li><Link to='/category'>Kategorija</Link></li>
                                            {
                                                this.props.breadcrumb.map((item, idx) => {
                                                    if (item)
                                                        return (
                                                            <li key={idx}><Link to={item.link}>{item.name}</Link></li>

                                                        )
                                                })
                                            }
                                        </ul>
                                    </Col>
                                    <Col lg="5">
                                        <h1>{this.props.breadcrumb.length && this.props.breadcrumb[this.props.breadcrumb.length - 1] ? this.props.breadcrumb[this.props.breadcrumb.length - 1].name : "KATEGORIJE"}</h1>
                                    </Col>
                                    <Col lg="7">
                                    </Col>
                                    <Col lg="12">
                                        <Container className="article-box">
                                        {product.discount ?
                                                        <div className="discount-detail">{product.discount}%</div>
                                                        :
                                                        null
                                                    }

                                            <Row>
                                                <Col lg="5" className="article-image">

                                                    <img className="preview" onError={() => { this.setState({ imageError: true }) }} src={!this.state.imageError ? this.state.previewImage ? this.state.previewImage : image : image} />

                                                    <div className="images" onMouseDown={this.onTouchStart} onMouseMove={this.onTouchMove} onMouseUp={this.onTouchEnd} ref={(input) => { this.carousel = input; }}>


                                                        {
                                                            product && product.Images && product.Images.map((image, idx) => {
                                                                return (

                                                                    <div onClick={() => this.setState({ previewImage: image })} className={this.state.previewImage == image ? "image active" : "image"}>
                                                                        <img draggable="false" src={image} />
                                                                    </div>


                                                                )
                                                            })
                                                        }






                                                    </div>

                                                </Col>
                                                <Col lg="7">

                                                    <h2>{product.Name && product.Name}</h2>

                                                    {product.StockLevel > 0 ?
                                                        <p className="stock"> <div className="in-stock"></div> Dostupno</p>
                                                        :
                                                        <p className="stock"> <div className="out-of-stock"></div> Nije dostupnp</p>
                                                    }
                                                    <div className="spacer"></div>

                                                    <div className="price">
                                                        {product.price && product.price !== '' && product.price !== 'NaN' ? parseFloat(product.price).toFixed(2) + "KM"
                                                            : null}

                                                        <span>Cijena sa PDV-om</span>

                                                    </div>
                                                    <div className="spacer"></div>
                                                    <div className="buttons">
                                                        {product.StockLevel > 0 && product.price && product.price !== '' && product.price !== 'NaN' ? <button className="button" onClick={this.addToCart}>{this.props.translate('Dodaj u korpu')}</button> : null}
                                                        <button className="button button-black" onClick={this.addToWishlist}>{this.props.translate('Dodaj u listu omiljenih')}</button>
                                                    </div>
                                                    {/*<div className="actions">
                                                        <div className="add-review">
                                                            <button className="button button-black"><Isvg src={star_icon}></Isvg> </button>
                                                            <p>Produkt jetzt als Erster bewerten</p>
                                                        </div>

                                                        <Link to='' className='ask-question'>{this.props.translate('Frage stellen')}</Link>

                                                </div>*/}

                                                </Col>
                                            </Row>
                                        </Container>

                                        <Container className="article-box">
                                            <Row>
                                                <Col dangerouslySetInnerHTML={{ __html: product.Description }} lg="12">
                                                </Col>
                                            </Row>
                                        </Container>



                                    </Col>

                                </Row>
                            </Container>

                        </Col>
                    </Row>
                </Container>

                <section className="section section-articles">
                    <Container>
                        <Row>
                            <Col lg="12">
                                <h2>
                                    {this.props.translate('NOVI')} <span>ARTIKLI</span>
                                </h2>
                            </Col>
                            {
                                this.props.newestProducts.map((item, idx) => {
                                    /*if (!item.price) {
                                        return null;
                                    }*/
                                    return (
                                        <Col lg="3" sm="6" xs="6" key={idx} className="article-container1">
                                            <Article
                                                _id={item._id}
                                                alias={item.Alias}
                                                discount={item.discount}
                                                image={item.Images && item.Images.length && item.Images[0]}
                                                title={item.Name}
                                                price={item.price ? item.price : 0}
                                                inStock={item.StockLevel > 0 ? true : false}
                                                translate={this.props.translate}>
                                            </Article>
                                        </Col>
                                    )
                                })
                            }

                        </Row>
                    </Container>
                </section>

                <Newsletter {...this.props} {...this.props} />


                <Footer {...this.props} />



            </div >
        );
    }
}



const mapStateToProps = state => ({
    menu: state.menu
});



export default connect(mapStateToProps)(PageWithLayout(DetailPage));
