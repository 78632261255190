import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export class BlogArticle extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }


    render() {
        return (
                <article>
                    <img src={this.props.image.image} />
                    <h6>{this.props.title}</h6>
                    <p>{this.props.shortDescription}</p>
                    <Link to=''>{this.props.translate('MEHR')}</Link>
                </article>
        )
    }
}

export default BlogArticle;