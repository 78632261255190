import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form'
import { Link } from 'react-router-dom';

import Text from './fields/text';
import Select from './fields/select';
import Check from './fields/check';

const required = value => value ? undefined : "Required"


const renderCheckField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
    translate
}) => (

        <Check
            label={label}
            translate={translate}
            {...input}
        />
    )


const renderTextField = ({
    input,
    placeholder,
    label,
    type,
    meta: { touched, error },
}) => (

        <Text
            placeholder={placeholder}
            label={label}
            type={type}
            errorText={touched && error}
            error={touched && error}

            {...input}
        />
    )

const renderSelectField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
    children,
}) => (

        <Select
            placeholder={placeholder}
            label={label}
            errorText={touched && error}
            error={touched && error}
            {...input}
            children={children}
        />
    )

const deliveryAddressForm = (props) => {
    const { handleSubmit, pristine, reset, submitting } = props;
    console.log(pristine, submitting);

    return (
        <form onSubmit={handleSubmit}>

         
            <Field
                name="FirstName"
                type="text"
                component={renderTextField}
                label={props.translate('Ime *')}
                placeholder=""
                validate={required}

            ></Field>

            <Field
                name="LastName"
                type="text"
                component={renderTextField}
                label={props.translate('Prezime *')}
                placeholder=""
                validate={required}

            ></Field>

            <Field
                name="Street"
                type="text"
                component={renderTextField}
                label={props.translate('Ulica *')}
                placeholder=""
                validate={required}

            ></Field>

            <Field
                name="Zipcode"
                type="text"
                component={renderTextField}
                label={props.translate('Poštanski broj *')}
                placeholder=""
                validate={required}

            ></Field>

            <Field
                name="City"
                type="text"
                component={renderTextField}
                label={props.translate('Grad *')}
                placeholder=""
                validate={required}

            ></Field>


           
                
            <Field
                name="EMail"
                type="email"
                component={renderTextField}
                label={props.translate('E-Mail adresa *')}
                placeholder=""
                validate={required}

            ></Field>

        


            <p>{props.translate('* Obavezna polja')}</p>

            <button type="submit" className="button">{props.translate('SPREMI')}</button>


        </form>

    )
}

export default reduxForm({
    form: 'deliveryAddressForm'  // a unique identifier for this form
})(deliveryAddressForm)
